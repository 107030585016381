* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.works-full-page{
    background-color: black;
    height: 100vh;
}
.item {
    min-height: 35rem;
    min-width: 43%;
    padding: 30px;
}
.item img {
    height: 90%;
    width: 100%;
    border-radius: 2rem;
    object-fit: cover;
    pointer-events: none;
}
.inner-carousel {
    display: flex;
}
.carousel {
    margin: 0% 19%;
    cursor: grab;
    overflow: hidden;
}
.slider-text{
    color: rgba(206, 206, 206, 0.904);
    font-size: 16px;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
a{
    text-decoration: none;
}
.text-div{
    height: 100px;
    padding: 5px;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}