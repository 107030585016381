@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.contact-full-page {
    width: 100%;
    height: 100vh;
    background: hsla(0, 0%, 0%, 1);

background: radial-gradient(circle, hsla(0, 0%, 0%, 1) 0%, hsla(0, 0%, 11%, 1) 61%);

background: -moz-radial-gradient(circle, hsla(0, 0%, 0%, 1) 0%, hsla(0, 0%, 11%, 1) 61%);

background: -webkit-radial-gradient(circle, hsla(0, 0%, 0%, 1) 0%, hsla(0, 0%, 11%, 1) 61%);

filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#000000", endColorstr="#1B1B1B", GradientType=1 );
}

.contact-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact-title{
    margin-top: 50px;
    text-align: center;
    margin-bottom: 45px;
    padding-bottom: 10px;
    
}
.contact-me {
    display:inline-block;
    font-size: 40px;
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    border-bottom: 2px solid rgb(175, 173, 173);
    padding-bottom: 7px;
}
.contact-me:hover{
    cursor: pointer;
    color: #1da8d3;
    transition: 0.4s;
    border-bottom: 2px solid rgb(255, 254, 254);

}


.wrapper-left {
    display: flex;
    flex-direction: column;
    margin-left: 60px;
    margin-right: 20px;
    margin-top: -58px;
}
.wrapper-left .all {
    width: 400px;
    height: 60px;
    margin-bottom: 42px;
    display: flex;
    align-items: center;
    background-color: rgb(36, 35, 35);
    border: none;
    border-radius: 5px;
    
}
.wrapper-left .all:hover{
    cursor: pointer;
    background-color: rgb(29, 28, 28);
    transition: 0.4s;
    
}
.contact-icon {
    margin-left: 20px;
    margin-right: 30px;
    font-size: 42px;
    color: #1da8d3;
    background-color: rgb(202, 199, 199);
    border-radius: 25px;
    padding: 6px;
}
.button-text {
    color: white;
    font-size: 17px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.contact-form{
    display: flex;
    flex-direction: column;
    margin-left: 50px;
}
.your-name{
    background-color: rgb(36, 35, 35);
    height: 40px;
    width: 500px;
    padding-left: 15px;
    border: none;
    font-size: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    color: white;
}
.your-name:hover {
    cursor: pointer;
    background-color: rgb(29, 28, 28);
    transition: 0.4s;
}
.your-name::placeholder{
    color: rgba(141, 140, 140, 0.452);
}
.your-name:focus{
    color: white;
    font-size: 20px;
    outline: none;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.your-email{
    background-color: rgb(36, 35, 35);
    height: 40px;
    width: 500px;
    padding-left: 15px;
    border: none;
    font-size: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    color: white;
}
.your-email:hover{
    cursor: pointer;
    background-color: rgb(29, 28, 28);
    transition: 0.4s;
}
.your-email::placeholder {
    color: rgba(141, 140, 140, 0.452);
}
.your-email:focus{
    color: white;
    font-size: 20px;
    outline: none;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.text-area {
    background-color: rgb(36, 35, 35);
    height: 200px;
    width: 500px;
    padding-left: 15px;
    border: none;
    font-weight: 300;
    margin-bottom: 15px;
    border-radius: 5px;
    font-size: 17px;
    resize: none;
    padding-top: 10px;
    color: white;
}
.text-area:hover{
    cursor: pointer;
    background-color: rgb(29, 28, 28);
    transition: 0.4s;
}
.text-area::placeholder {
    color: rgba(141, 140, 140, 0.452);
    font-weight: 400;
    font-size: 20px;
}
.text-area:focus{
    color: white;
    font-size: 17px;
    outline: none;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.send{
    background-color: #1da8d3;
    border: none;
    width: 120px;
    height: 30px;
    border-radius: 8px;
    color: rgba(245, 245, 245, 0.911);
    font-size: 20px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.send:hover{
    color: white;
    background-color: #1980a0;
    cursor: pointer;
    transition: 0.4s;
}

