@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Text:wght@400;500;700&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.about-full-page {
    width: 100%;
    height: 100vh;
    background: hsla(0, 0%, 0%, 1);

background: radial-gradient(circle, hsla(0, 0%, 0%, 1) 0%, hsla(0, 0%, 11%, 1) 61%);

background: -moz-radial-gradient(circle, hsla(0, 0%, 0%, 1) 0%, hsla(0, 0%, 11%, 1) 61%);

background: -webkit-radial-gradient(circle, hsla(0, 0%, 0%, 1) 0%, hsla(0, 0%, 11%, 1) 61%);

filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#000000", endColorstr="#1B1B1B", GradientType=1 );
    
    
    
}
.about-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.photo-side {
    width: 50%;
    height: 86.5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.text-side {
    width: 50%;
    height: 86.5vh;
    padding-right: 70px;
    padding-top: 60px;
}
.about-title {
    color: white;
    border-bottom: 2px solid rgba(187, 241, 245, 0.678);
    width: 120px;
    margin-bottom: 7px;
    font-family: 'Wix Madefor Text', sans-serif;
    font-weight: 600;
    
}
.about-email {
    color: rgba(255, 255, 255, 0.795);
    font-size: 18px;
    margin-bottom: 80px;
    font-family: 'Wix Madefor Text', sans-serif;
    font-weight: 400;
}
.about-description {
    color: rgba(255, 255, 255, 0.925);
    font-size: 20px;
    font-family: 'Wix Madefor Text', sans-serif;
    font-weight: 400;
}
.person-image {
    width: 380px;
    opacity: 0.8;
    box-shadow: 6px 6px 20px #6e6d6d;
}
